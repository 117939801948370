import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import purpleIcon from '/src/dummy-data/images/purpleIcon'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const ImageBulletList = loadable(() => import('/src/components/Lists/ImageBulletList'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const ImageBullets = ({
    backgroundColour,
    titleArea,
    columns,
    itemsRepeater,
    addExtended,
    extendedContent,
    addCta,
    callToAction,
    addSmallprint,
    smallprint,
    utm
}) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-image-bullets'}>
            <div className="row">
                <div className={columns === '2' ? 'col-lg-10 offset-lg-1' : 'col-lg-8 offset-lg-2'}>
                    {itemsRepeater && <ImageBulletList columns={columns} itemsRepeater={itemsRepeater} />}

                    {addExtended && extendedContent && (
                        <div className="c-image-bullets__extended">{parse(extendedContent)}</div>
                    )}

                    {addCta && callToAction && (
                        <CtaContainer align={'centered'} variant={'large'}>
                            <ButtonLink data={callToAction} size={'lg'} icon={'arrow'} utm={utm} />
                        </CtaContainer>
                    )}

                    {addSmallprint && smallprint && <ContentDisclaimer content={smallprint} />}
                </div>
            </div>
        </Section>
    )
}

ImageBullets.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    columns: PropTypes.oneOf(['1', '2']),
    itemsRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.object,
            title: PropTypes.string,
            description: PropTypes.string
        })
    ).isRequired,
    addExtended: PropTypes.bool,
    extendedContent: PropTypes.string,
    addCta: PropTypes.bool,
    callToAction: PropTypes.shape({
        url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        target: PropTypes.string
    }),
    addSmallprint: PropTypes.bool,
    smallprint: PropTypes.string
}

ImageBullets.defaultProps = {
    backgroundColour: 'light',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'How does it work?'
    },
    columns: '1',
    itemsRepeater: [
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        },
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        },
        {
            image: purpleIcon,
            title: 'Item title',
            description: 'Item description'
        }
    ],
    addExtended: false,
    extendedContent: '<p>I am some extended content</p>',
    addCta: false,
    callToAction: {
        url: '#',
        title: 'Button Title'
    },
    addSmallprint: false,
    smallprint: '<p>I am some smallprint</p>'
}

export default ImageBullets

export const query = graphql`
    fragment ImageBulletsForPageBuilder on WpPage_Acfpagebuilder_Layouts_ImageBullets {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        columns
        itemsRepeater {
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(height: 150)
                    }
                }
                altText
                title
            }
            title
            description
        }
        addExtended
        extendedContent
        addCta
        callToAction {
            url
            title
            target
        }
        addSmallprint
        smallprint
    }
`
